import React from 'react'
import { Link, PageProps } from 'gatsby'
import classnames from 'classnames'
import { arrow, category, container } from './NewsHeader.module.scss'
import { ArrowRight } from '../../ui/Icon'
import { Title } from '../../ui/Title'

type Props = {
	location: PageProps['location']
}

const indexPath = ['/news', '/news/press', '/news/info']

export const NewsHeader = ({ location }: Props) => {
	return (
		<div className={container}>
			<div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
				<Title title="ニュース" titleSub="NEWS" tag="h1" horizontal={false} className="flex-col" />
				<div className="mx-auto lg:mx-0 mt-12 lg:mt-0">
					<Link
						to="/news"
						className={classnames(
							location.pathname === '/news' ? category : '',
							'inline-flex relative items-center text-xs lg:text-sm text-gray-darken hover:opacity-70 duration-300 cursor-pointer'
						)}
					>
						すべて
						{indexPath.indexOf(location.pathname) === -1 ? (
							<span className={classnames(arrow, 'ml-2 fill-secondary stroke-grayDarken')}>
								<ArrowRight />
							</span>
						) : null}
					</Link>
					<Link
						to="/news/press"
						className={classnames(
							location.pathname === '/news/press' ? category : '',
							'inline-flex relative items-center ml-7 lg:ml-12 text-xs lg:text-sm text-gray-darken hover:opacity-70 duration-300 cursor-pointer'
						)}
					>
						プレスリリース
						{indexPath.indexOf(location.pathname) === -1 ? (
							<span className={classnames(arrow, 'ml-2 fill-secondary stroke-grayDarken')}>
								<ArrowRight />
							</span>
						) : null}
					</Link>
					<Link
						to="/news/info"
						className={classnames(
							location.pathname === '/news/info' ? category : '',
							'inline-flex relative items-center ml-7 lg:ml-12 text-xs lg:text-sm text-gray-darken hover:opacity-70 duration-300 cursor-pointer'
						)}
					>
						お知らせ
						{indexPath.indexOf(location.pathname) === -1 ? (
							<span className={classnames(arrow, 'ml-2 fill-secondary stroke-grayDarken')}>
								<ArrowRight />
							</span>
						) : null}
					</Link>
				</div>
			</div>
		</div>
	)
}

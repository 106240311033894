import React, { useEffect, useState } from 'react'
import { FacebookIcon } from '../../ui/Icon'
import { twitterBg } from './Share.module.scss'

type Props = {
	id: string
	title: string
	category: string
}

export const Share = ({ id, title }: Props) => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = 'https://b.st-hatena.com/js/bookmark_button.js'
		script.async = true
		script.defer = true
		script.onload = () => setMounted(true)
		document.body.append(script)
	}, [])

	return (
		<div className="flex justify-start mt-8">
			{mounted ? (
				<a
					href="https://b.hatena.ne.jp/entry/"
					className="hover:opacity-70 duration-300 hatena-bookmark-button"
					data-hatena-bookmark-layout="basic-label-counter"
					data-hatena-bookmark-lang="ja"
					data-hatena-bookmark-width="120"
					data-hatena-bookmark-height="20"
					title="このエントリーをはてなブックマークに追加"
				>
					<img
						src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
						alt="このエントリーをはてなブックマークに追加"
						width="20"
						height="20"
						className="border-none"
					/>
				</a>
			) : null}
			<a
				href={`http://www.facebook.com/share.php?u=https://lisatech.jp/news/${id}`}
				className="inline-flex justify-around items-center py-0.5 ml-3 w-20 bg-facebook rounded-mini hover:opacity-70 duration-300"
			>
				<FacebookIcon className="bg-facebook rounded-circle" />
				<span className="text-xs font-bold text-secondary">シェア</span>
			</a>
			<a
				href={`https://twitter.com/share?url=https://lisatech.jp/news/${id}&text=${title}`}
				className="inline-flex justify-around items-center py-0.5 ml-3 w-20 bg-twitter rounded-mini hover:opacity-70 duration-300"
			>
				<span className={twitterBg} />
				<span className="text-xs font-bold text-secondary">ツイート</span>
			</a>
		</div>
	)
}

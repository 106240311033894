import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classnames from 'classnames'
import {
	container,
	eyeCatchBgFdm,
	eyeCatchBgLisa,
	eyeCatchImage,
	indexLink,
	linkContainer,
	postStyle,
} from './News.module.scss'
import { Layout } from '../components/common/Layout'
import { SEO } from '../components/common/SEO'
import { CompanyInfo, NewsHeader, Share } from '../components/domains/news'
import { ArrowRight } from '../components/ui/Icon'
import { InternalLink } from '../components/ui/Link'

const EyeCatchImageBg: React.FC<{ category: string }> = ({ category }) => {
	return (
		<div
			className={classnames(
				category === 'FDMリリース' ? eyeCatchBgFdm : eyeCatchBgLisa,
				'bg-center bg-no-repeat bg-contain rounded-l border'
			)}
		/>
	)
}

export default function News({ data, ...props }) {
	const { location } = props as PageProps

	const post = data.allWpPost.nodes[0]
	const category = post.categories.nodes[0].name
	const image = post.featuredImage !== null && post.featuredImage.node.localFile.childImageSharp.gatsbyImageData

	let ogpImage
	if (category === 'FDMリリース') {
		ogpImage = '/images/lp_ogp_fdm.png'
	} else if (category === 'プレスリリース') {
		ogpImage = '/images/lp_ogp_mission.jpg'
	} else {
		ogpImage = '/images/lp_ogp_lisa.png'
	}

	return (
		<Layout
			location={[
				{ name: 'ニュース', path: '/news' },
				{ name: post.title.length > 14 ? post.title.substr(0, 14) + '...' : post.title, path: `/news/${post.id}` },
			]}
		>
			<SEO title={`${post.title}`} description={post.title} image={ogpImage} />
			<NewsHeader location={location} />
			<div className={container}>
				<div className="flex justify-start items-center">
					<span className="py-1 px-2 w-28 text-xs text-center text-secondary bg-gray rounded-big">{category}</span>
					<small className="ml-5 text-sm">{post.date}</small>
				</div>
				<h2 className="mt-3 lg:mt-5 text-xl lg:text-2xl font-bold">{post.title}</h2>
				<Share id={post.id} title={post.title} category={category} />
				<div className="mt-10 lg:mt-14">
					{post.featuredImage === null ? (
						<EyeCatchImageBg category={category} />
					) : (
						<>
							<GatsbyImage
								image={image}
								alt={`${post.title}のアイキャッチ画像`}
								className={classnames(eyeCatchImage, 'object-contain rounded-l')}
								objectFit="contain"
								objectPosition="center"
							/>
							<div
								className="mt-5 lg:mt-8 text-xs text-center"
								dangerouslySetInnerHTML={{
									__html: post.featuredImage.node.caption,
								}}
							/>
						</>
					)}
					<div className="mt-10">
						<div
							className={postStyle}
							dangerouslySetInnerHTML={{
								__html: post.content,
							}}
						/>
						<p className="mt-10 lg:mt-16 text-sm">（以上）</p>
					</div>
				</div>
			</div>
			<CompanyInfo author={post.author.node} category={category} />
			<div className={linkContainer}>
				<InternalLink href="/news" className={classnames(indexLink, 'inline-flex justify-between items-center')}>
					<span className="z-10 text-sm">一覧に戻る</span>
					<ArrowRight />
				</InternalLink>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query ($id: String!) {
		allWpPost(filter: { id: { eq: $id } }) {
			nodes {
				date(formatString: "YYYY.MM.DD")
				id
				title
				author {
					node {
						name
						lastName
						firstName
					}
				}
				featuredImage {
					node {
						id
						sizes
						sourceUrl
						srcSet
						caption
						localFile {
							id
							childImageSharp {
								id
								gatsbyImageData(layout: FIXED, height: 400, quality: 100)
							}
						}
					}
				}
				categories {
					nodes {
						id
						name
						slug
					}
				}
				content
			}
		}
	}
`
